import { AppBar, Chip, Divider, Toolbar, withStyles } from '@material-ui/core';
import clsx from 'clsx';
import { inject } from 'mobx-react';
import React from 'react';
import AppController from '../../../base/App.controller';
import chevronDown from '../../../images/chevron-down.svg';
import chevronUp from '../../../images/chevron-up.svg';
import LogoWrapper from '../logo/Logo';
import MenuWrapper from './Menu';
import { IToolbarWrapper } from '../types';
import { Itranslation } from 'types';

const { publicRuntimeConfig } = require('next/config').default();

const styles = () => ({
    root: {
        '& .toolbar': {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            height: '100%',
            width: '100%',
            color: 'black',
        },
        '& .leftSideToolbar': {
            display: 'flex',
            alignItems: 'center',
            color: 'var(--milled-wine)',
            textTransform: 'uppercase',
            fontWeight: 'var(--font-weight-medium)',
            fontSize: 'var(--fs-small)',
            letterSpacing: '0.12em',
            height: '100%',
        },
        '& .rightSideToolbar': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            cursor: 'pointer',
        },
        '& .userName': {
            fontSize: 'var(--fs-medium)',
            fontWeight: 'var(--font-weight-medium)',
            color: 'var(--revolver)',
            textAlign: 'right',
            opacity: '0.85',
        },
        '& .chip': {
            fontSize: '11px',
            height: '25px',
            letterSpacing: '1px',
            textTransform: 'none',
        },
        '& .production': { backgroundColor: '#621F87', color: 'white' },
        '& .sandbox': { backgroundColor: '#F8F8F8' },
        '& .email': { fontSize: '14px', color: 'var(--spun-pearl)' },
        '& .chevron': { marginLeft: '25px' },
        '& .divider': { margin: '0 15px' },
    },
    // NOTE(Jules): This style overrides the original navbar toolbar styles. Don't remove this without removing the associated scope theme overrides.
    rootToolbar: {
        padding: '30px 50px',
    },
});

const ToolbarWrapper: React.FC<IToolbarWrapper> = ({ AppStore, classes, hideMenu }) => {
    const anchorRef = React.createRef<HTMLDivElement>();
    const [isOpen, setOpen] = React.useState<boolean>(false);
    const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);
    const { t }: Itranslation = AppController.getTranslation(AppStore?.host, 'common');

    const env = publicRuntimeConfig.features.prod ? 'production' : 'sandbox';

    const handleClick = () => {
        /* istanbul ignore next */
        if (anchorRef.current) setAnchorEl(anchorRef.current);
        setOpen(!isOpen);
    };

    return (
        <AppBar position="fixed" className={clsx('toolbarContainer', classes.root)}>
            <Toolbar ref={anchorRef} className={classes.rootToolbar}>
                <div className="toolbar">
                    <div className="leftSideToolbar">
                        <LogoWrapper className="MuiToolbarLogo" />
                        <Divider className="divider" orientation="vertical" flexItem />
                        <Chip className={`chip ${env}`} label={t(env)} />
                    </div>
                    {!hideMenu && (
                        <div className="rightSideToolbar" onClick={handleClick}>
                            <div>
                                <div className="userName">{`${AppStore?.user?.firstName} ${AppStore?.user?.lastName}`}</div>
                                <div className="email">{AppStore?.user?.email}</div>
                            </div>
                            <div className="chevron">
                                <img src={isOpen ? chevronUp : chevronDown} />
                            </div>
                        </div>
                    )}
                </div>
                {!hideMenu && (
                    <MenuWrapper anchorEl={anchorEl} open={isOpen} onClose={handleClick} />
                )}
            </Toolbar>
        </AppBar>
    );
};

export default withStyles(styles, { name: 'MuiToolbarWrapper' })(
    inject('AppStore')(ToolbarWrapper),
);
