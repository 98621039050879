import axios from 'axios';
import { action, computed, configure, flow, observable } from 'mobx';
import { useStaticRendering } from 'mobx-react';
import moment from 'moment';
import Router from 'next/router';
import { isEmpty, isEqual } from 'lodash';

import ModalStore from 'components/common/modals/ModalStore';
import ColorPickerStore from './css/color-picker/ColorPickerStore';

// helpers
import asyncReadFile from 'helpers/async-read-file/asyncReadFile';
import conditional from 'helpers/conditional/conditional';
import escapeEmptyTags from 'helpers/escape-empty-tags/escape-empty-tags';
import sanitizeHTML from 'helpers/sanitize-html/sanitize-html';

// constants
import {
    BULK_TRANSFER,
    FUTURE_DATED,
    REQUEST_REFUND_ACCOUNT,
    SINGLE_IMMEDIATE_PAYMENT,
    STANDING_ORDER,
} from 'constants/memberPermissions';
import { TYPE_ONE } from 'constants/memberTypes';
import { getMessages } from 'constants/messages';
import { AIS, PIS, VRP } from 'constants/permissions';
import { getDefaultCss, getDefaultFontSize } from 'constants/defaultCss';

const isServer = typeof window === 'undefined';
useStaticRendering(isServer);
configure({
    enforceActions: 'observed',
});

class CustomizationStore {
    constructor(rootStore) {
        this.rootStore = rootStore;
        this.GenericSuccessStore = new ModalStore();
    }

    defaultConsentText = [
        {
            type: 'paragraph',
            children: [{ text: '' }],
        },
    ];
    defaultColorOptions = {
        'color-dark': {
            value: getDefaultCss['color-dark'],
            colorPickerStore: new ColorPickerStore(),
        },
        'color-text': {
            value: getDefaultCss['color-text'],
            colorPickerStore: new ColorPickerStore(),
        },
        'color-default-text': {
            value: getDefaultCss['color-default-text'],
            colorPickerStore: new ColorPickerStore(),
        },
        'color-spinner': {
            value: getDefaultCss['color-spinner'],
            colorPickerStore: new ColorPickerStore(),
        },
        'color-link': {
            value: getDefaultCss['color-link'],
            colorPickerStore: new ColorPickerStore(),
        },
        'color-primary': {
            value: getDefaultCss['color-primary'],
            colorPickerStore: new ColorPickerStore(),
        },
        'color-light': {
            value: getDefaultCss['color-light'],
            colorPickerStore: new ColorPickerStore(),
        },
        'color-primary-text': {
            value: getDefaultCss['color-primary-text'],
            colorPickerStore: new ColorPickerStore(),
        },
        'color-transparent': {
            value: getDefaultCss['color-transparent'],
            colorPickerStore: new ColorPickerStore(),
        },
        'color-secondary': {
            value: getDefaultCss['color-secondary'],
            colorPickerStore: new ColorPickerStore(),
        },
        'color-dividers': {
            value: getDefaultCss['color-dividers'],
            colorPickerStore: new ColorPickerStore(),
        },
        'color-sub-title-text': {
            value: getDefaultCss['color-sub-title-text'],
            colorPickerStore: new ColorPickerStore(),
        },
        'color-body-text': {
            value: getDefaultCss['color-body-text'],
            colorPickerStore: new ColorPickerStore(),
        },
        'color-primary-focus': {
            value: getDefaultCss['color-primary-focus'],
            colorPickerStore: new ColorPickerStore(),
        },
        'color-primary-press': {
            value: getDefaultCss['color-primary-press'],
            colorPickerStore: new ColorPickerStore(),
        },
        'color-primary-hover': {
            value: getDefaultCss['color-primary-hover'],
            colorPickerStore: new ColorPickerStore(),
        },
        'color-secondary-focus': {
            value: getDefaultCss['color-secondary-focus'],
            colorPickerStore: new ColorPickerStore(),
        },
        'color-secondary-press': {
            value: getDefaultCss['color-secondary-press'],
            colorPickerStore: new ColorPickerStore(),
        },
        'color-secondary-hover': {
            value: getDefaultCss['color-secondary-hover'],
            colorPickerStore: new ColorPickerStore(),
        },
        'color-delete-error': {
            value: getDefaultCss['color-delete-error'],
            colorPickerStore: new ColorPickerStore(),
        },
        'color-error-hover': {
            value: getDefaultCss['color-error-hover'],
            colorPickerStore: new ColorPickerStore(),
        },
        'color-error-press': {
            value: getDefaultCss['color-error-press'],
            colorPickerStore: new ColorPickerStore(),
        },
        'color-error-focus': {
            value: getDefaultCss['color-error-focus'],
            colorPickerStore: new ColorPickerStore(),
        },
        'color-focus-outline': {
            value: getDefaultCss['color-focus-outline'],
            colorPickerStore: new ColorPickerStore(),
        },
    };
    defaultFontSizeOptions = {
        'font-size-xlarge': { value: getDefaultFontSize['font-size-xlarge'] },
        'font-size-large': { value: getDefaultFontSize['font-size-large'] },
        'font-size-medium': { value: getDefaultFontSize['font-size-medium'] },
        'font-size-small': { value: getDefaultFontSize['font-size-small'] },
        'font-size-xsmall': { value: getDefaultFontSize['font-size-xsmall'] },
    };
    defaultFontWeightOptions = {
        'font-weight-bold': { value: '600' },
        'font-weight-semi-bold': { value: '400' },
        'font-weight-dark': { value: '600' },
        'font-weight-medium': { value: '500' },
        'font-weight-regular': { value: '400' },
        'font-weight-light': { value: '400' },
    };
    defaultFontWeightValues = [100, 200, 300, 400, 500, 600, 700, 800, 900];
    defaultFontFamilyValues = ['Inter', 'sans-serif', 'DM Sans', 'Source Sans Pro'];
    regionsValues = ['eu', 'gb'];
    @observable
    loading = false;
    @observable
    customization = {
        colors: {},
        logo: {},
    };
    @observable
    features = {
        fonts: {},
    };
    @observable
    consent = {
        text: '',
        name: 'Token.io',
    };
    @observable
    isAispOnly = false;
    @observable
    consentPlaceholder = '{DISPLAY_NAME} custom consent text for {BUSINESS_NAME}';
    @observable
    displayName = '';
    @observable
    previewText = '';
    @observable
    logo = {};
    @observable
    colorOptions = { ...this.defaultColorOptions };
    @observable
    fontSizeOptions = { ...this.defaultFontSizeOptions };
    @observable
    fontWeightOptions = { ...this.defaultFontWeightOptions };
    @observable
    isChanged = false;
    @observable
    isCustomizationFetched = false;
    @observable
    hideConsent = false;
    @observable
    reversePaymentSupportText = '';
    // NOTE: Update src/variables/_webappConfigurations.scss too while updating the default font family here.
    @observable
    fontPrimary = "'DM Sans','Source Sans Pro', sans-serif";
    @observable
    language = '';
    @observable
    consentToggle = false;
    @observable
    region = 'eu';
    @observable
    regionalConfigurations = {};
    @observable
    isReversePayment = false;
    @observable
    consentType = '';

    @action
    handleChange = (event, type) => {
        this.consent[event.target.name] = event.target.value;
        this.handlePreview(type, true);
    };

    @action
    handleReversePaymentSupportChange = (value, type) => {
        this.reversePaymentSupportText = this.isReversePayment ? value : '';
        this.handlePreview(type, true);
    };

    @action
    handleColorChange = (event) => {
        this.colorOptions[event.target.name].value = event.target.value;
        this.updateDisplayFeatures();
    };

    @action
    handleCSSChange = (event, key) => {
        this[key][event.target.name].value = event.target.value;
        this.updateDisplayFeatures();
    };

    @action
    handleFontFamilyChange = (event) => {
        this.fontPrimary = event.target.value;
        this.updateDisplayFeatures();
    };

    @action
    setColors = (colors) => {
        Object.keys(colors).forEach((key) => {
            this.colorOptions[key].value = colors[key];
        });
    };

    @action
    setFonts = (fonts) => {
        this.fontPrimary = fonts?.['font-primary']
            ? fonts?.['font-primary'].split(',')[0].replace(/'/g, '')
            : this.fontPrimary;
        this.setFontWeights(fonts);
        this.setFontSize(fonts);
    };

    @action
    setFontWeights = (fonts) => {
        Object.keys(fonts).forEach((key) => {
            this.fontWeightOptions[key] ? (this.fontWeightOptions[key].value = fonts[key]) : '';
        });
    };

    @action
    setFontSize = (fonts) => {
        Object.keys(fonts).forEach((key) => {
            this.fontSizeOptions[key]
                ? (this.fontSizeOptions[key].value = fonts[key].replace('px', ''))
                : '';
        });
    };

    @action
    setLanguage = (lang) => {
        this.language = lang;
    };

    @action
    setRegion = (region) => {
        this.region = region;
    };

    @action
    setConsentText = (value) => {
        this.consent.text = value;
        this.consent.name = this.consent.name || 'Token.io';
    };

    @action
    setBusinessName = (value) => {
        this.consent = {
            ...this.consent,
            name: value,
        };
    };

    @action
    setRegionalTranslations = (region, translations) => {
        this.regionalConfigurations[region] = {
            translations: {
                ...this.regionalConfigurations[region]?.translations,
                ...translations,
            },
        };
    };

    @action
    setRegionalConfigurations = (regionalConfigurations, consentType) => {
        this.regionalConfigurations = {};
        const defaultValues = { ...getMessages[consentType] };
        consentType = this.flowType(consentType);
        this.consentType = consentType;
        this.regionsValues.forEach((region) => {
            this.setRegionalTranslations(region, defaultValues);
        });
        if ('default' in regionalConfigurations) {
            this.regionsValues.map((reg) => {
                const languageTranslations = {};
                const { translations } = regionalConfigurations['default'];
                Object.keys(translations).forEach((key) => {
                    if (key.includes(`${consentType}.consent.text`)) {
                        if (key === `${consentType}.consent.text` && translations[key] === '') {
                            // do nothing
                        } else if (
                            key === `${consentType}.consent.text` &&
                            `en.${consentType}.consent.text` in translations
                        ) {
                            // do nothing
                        } else if (
                            key === `${consentType}.consent.text` &&
                            !(`en.${consentType}.consent.text` in translations)
                        ) {
                            languageTranslations[`en.${consentType}.consent.text`] =
                                translations[key];
                        } else {
                            languageTranslations[key] = translations[key];
                        }
                    }
                });
                this.setRegionalTranslations(reg, languageTranslations);
            });
        }

        Object.keys(regionalConfigurations).forEach((reg) => {
            if (reg !== 'default') {
                const languageTranslations = {};
                const { translations } = regionalConfigurations[reg];
                Object.keys(translations).forEach((key) => {
                    if (key.includes(`${consentType}.consent.text`)) {
                        languageTranslations[key] = translations[key];
                    }
                });
                this.setRegionalTranslations(reg, languageTranslations);
            }
        });
        this.setRegion(
            conditional(this.language, this.region, Object.keys(this.regionalConfigurations)[0]),
        );
        this.setLanguage(
            conditional(
                this.language,
                this.language,
                Object.keys(this.regionalConfigurations[this.region].translations)[0].substring(
                    0,
                    2,
                ),
            ),
        );
        let consentText = this.regionalConfigurations[this.region].translations[
            `${this.language}.${consentType}.consent.text`
        ];
        if (consentType === 'pisp') {
            consentText = this.getConsentText(consentText);
        }
        this.setConsentText(consentText);
        this.onConsentToggle();
    };

    @action
    restoreDefaultConsentText = (language, type) => {
        const t = this.flowType(type);
        let text = getMessages[`${type}`][`${language}.${t}.consent.text`];
        if (t === 'pisp') {
            text = this.getConsentText(text);
        }
        this.setConsentText(text);
    };

    @action
    onConsentToggle = () => {
        this.consentToggle = !this.consentToggle;
    };

    @action
    getConsentText = (consentText) => {
        let cText = consentText;
        let reversePaymentText;
        const wrapper = document.createElement('div');
        wrapper.innerHTML = cText;
        const el = wrapper.getElementsByClassName('rt-reverse-payments-support')[0];
        if (el) {
            reversePaymentText = el.innerHTML;
            el.remove();
            cText = wrapper.innerHTML;
        }
        if (reversePaymentText) {
            this.handleReversePaymentSupportChange(reversePaymentText);
            consentText = cText;
        } else {
            this.handleReversePaymentSupportChange('');
        }
        return consentText;
    };

    @action
    getConsentTextWithoutReversePayment = (consentText) => {
        let cText = consentText;
        const wrapper = document.createElement('div');
        wrapper.innerHTML = cText;
        const el = wrapper.getElementsByClassName('rt-reverse-payments-support')[0];
        if (el) {
            el.remove();
            cText = wrapper.innerHTML;
        }
        consentText = cText;
        return consentText;
    };

    @action
    handleUpload = flow(
        function* (event) {
            const file = event.target.files[0];
            const data = yield asyncReadFile(file, true);
            this.logo = {
                data,
                mimeType: file.type,
            };
            this.updateDisplayFeatures();
        }.bind(this),
    );

    @computed
    get isConsentFormFilled() {
        const refundText = this.reversePaymentSupportText
            ? this.addRpsClass(this.consent.text) + this.reversePaymentSupportText + '</div>'
            : '';
        return (
            this.consent.name &&
            this.consent.text !== '<br>' &&
            (this.consent.text + refundText !== this.customization.consentText ||
                this.consent.name !== this.customization.name)
        );
    }

    @computed
    get isUploaded() {
        return !!this.logo.data;
    }

    @computed
    get isFilled() {
        return this.isUploaded || !isEmpty(this.constructCSS());
    }

    @computed
    get isFormChanged() {
        let isLogoChanged = false;

        if (this.logo.data) {
            const blocks = this.logo.data.split(';');
            const data = blocks[1].split(',')[1];
            isLogoChanged = this.customization.logo?.data !== data;
        } else if (this.customization.logo?.data) {
            isLogoChanged = true;
        }

        return (
            isLogoChanged ||
            !isEqual(this.features.fonts, this.constructFonts()) ||
            !isEqual(this.customization.colors, this.constructCSS())
        );
    }

    @action
    initCustomizations = () => {
        this.setCustomization({}, {});
        this.colorOptions = { ...this.defaultColorOptions };
    };

    @action
    getCustomization = flow(
        function* (member, type) {
            try {
                this.isCustomizationFetched = false;
                this.hideConsent = true;
                const { memberType, aisCustomizationId, pisCustomizationId } = member;
                const { features } = this.rootStore.FeatureStore;
                const { fonts } = features;
                this.setBusinessName('');
                if ((type === AIS && aisCustomizationId) || (type === PIS && pisCustomizationId)) {
                    const email = this.rootStore.memberEmail;
                    const scope = this.rootStore.scope;
                    const res = yield axios.get(
                        `/api/member/customization?type=${type}&email=${encodeURIComponent(
                            email,
                        )}&scope=${scope}&memberId=${conditional(
                            this.rootStore.refId,
                            this.rootStore.refId,
                            member.memberId,
                        )}`,
                    );
                    this.customization = res.data;

                    const { logo, colors, name } = this.customization;
                    this.consent = setConsent(memberType, {
                        text: '',
                        name,
                    });
                    this.setCustomization(logo, colors, fonts);
                }
                if (type === AIS || type === PIS || type === VRP) {
                    const { regionalConfigurations, colors, fonts, tppLogo, ais, pis } = features;
                    if (tppLogo?.data) {
                        this.logo = {
                            data: tppLogo.data,
                            mimeType: tppLogo.mimeType,
                        };
                        this.setColors(colors);
                        this.setFonts(fonts);
                    } else {
                        this.setCustomization({}, colors, fonts);
                    }
                    if (type === AIS) {
                        this.setBusinessName(ais?.tags?.businessName);
                    } else if (type === PIS) {
                        this.setBusinessName(pis?.tags?.businessName);
                    }
                    this.setRegionalConfigurations(regionalConfigurations, type);
                }
                if (!type) {
                    const { features } = this.rootStore.FeatureStore;
                    const { tppLogo, colors } = features;
                    if (isEmpty(tppLogo) || isEmpty(tppLogo.data)) {
                        yield this.getCustomization(member, AIS);
                    } else {
                        const blocks = tppLogo.data.split(';');
                        this.customization.logo = {};
                        this.customization.logo.data = blocks[1].split(',')[1];
                        this.customization.colors = colors;
                        this.logo = tppLogo;
                        this.setCustomization({}, colors, fonts);
                    }
                }
                this.features.fonts = fonts;
                this.isCustomizationFetched = true;
                this.hideConsent = false;
            } catch (e) {
                console.error(e);
            }
        }.bind(this),
    );

    @action
    setCustomization = (logo, colors, fonts = {}) => {
        if (logo?.data) {
            this.logo = {
                data: `data:${logo.type};base64,${logo.data}`,
                mimeType: logo.type,
            };
        }
        this.setColors(colors);
        this.setFonts(fonts);
    };

    @action
    saveFeatures = flow(
        function* () {
            const member = this.rootStore.member;
            const memberId = conditional(
                this.rootStore.refId,
                this.rootStore.refId,
                member.memberId,
            );
            const res = yield axios.get(`/api/tppIntegration/features/${memberId}`);
            const { configurations, bankSelectionPreset } = res.data;
            let features = this.rootStore.FeatureStore.filteredFeatures();

            const fonts = this.constructFonts();
            // Adding Parent fonts if Key is empty or set it to Default CSS
            const parentCssData = this.rootStore.FeatureStore.parentCssData;
            const tppCssData = this.rootStore.FeatureStore.tppCssData;
            const refId = this.rootStore.FeatureStore.refId;
            Object.keys(this.fontSizeOptions).forEach((key) => {
                if (!this.fontSizeOptions[key].value) {
                    if (refId) {
                        if (tppCssData?.fonts?.[key]) {
                            fonts[key] =
                                tppCssData?.fonts?.[key] === 'px'
                                    ? getDefaultFontSize[key] + 'px'
                                    : tppCssData?.fonts?.[key];
                        } else if (parentCssData?.fonts?.[key]) {
                            fonts[key] =
                                parentCssData?.fonts?.[key] === 'px'
                                    ? getDefaultFontSize[key] + 'px'
                                    : parentCssData?.fonts?.[key];
                        } else {
                            fonts[key] = getDefaultFontSize[key] + 'px';
                        }
                    } else {
                        if (parentCssData?.fonts?.[key]) {
                            fonts[key] =
                                parentCssData?.fonts?.[key] === 'px'
                                    ? getDefaultFontSize[key] + 'px'
                                    : parentCssData?.fonts?.[key];
                        } else {
                            fonts[key] = getDefaultFontSize[key] + 'px';
                        }
                    }
                }
            });

            features = {
                ...features,
                fonts: fonts,
                regionalConfigurations: {
                    ...configurations.regionalConfigurations,
                },
            };
            yield axios.post('/api/tppIntegration/features', {
                memberId: memberId,
                features: features,
                bankSelectionPreset,
            });
        }.bind(this),
    );

    @action
    createCustomization = flow(
        function* (type, tabName = '') {
            const member = this.rootStore.member;
            let { text } = this.consent;
            if (this.reversePaymentSupportText) {
                text += this.addRpsClass(text) + this.reversePaymentSupportText + '</div>';
            }
            this.consent = {
                ...this.consent,
                text: sanitizeHTML(escapeEmptyTags(text)),
            };
            const email = this.rootStore.memberEmail;
            const scope = this.rootStore.scope;
            const memberType = this.rootStore.memberType;
            if (tabName !== 'consent') {
                yield this.saveFeatures();
            }
            const lang = tabName === 'consent' ? this.language : '';
            const region = tabName === 'consent' ? this.region : '';

            const colors = this.constructCSS();

            // Adding Parent Css if Key is empty or set it to Default CSS
            const parentCssData = this.rootStore.FeatureStore.parentCssData;
            const tppCssData = this.rootStore.FeatureStore.tppCssData;
            Object.keys(this.colorOptions).forEach((key) => {
                if (!this.colorOptions[key].value) {
                    colors[key] =
                        tppCssData?.colors?.[key] ||
                        parentCssData?.colors?.[key] ||
                        getDefaultCss[key];
                }
            });

            const res = yield axios.post(
                `/api/member/customization?email=${encodeURIComponent(
                    email,
                )}&scope=${scope}&refId=${
                    this.rootStore.refId
                }&memberType=${memberType}&language=${lang}&region=${region}`,
                {
                    memberId: member.memberId,
                    consent: this.consent,
                    logo: this.logo,
                    colors: colors,
                    type,
                    displayName: this.displayName,
                },
            );

            const customizationIds = res.data;
            const aisCustomizationId = customizationIds[AIS] || member.aisCustomizationId;
            const pisCustomizationId = customizationIds[PIS] || member.pisCustomizationId;

            this.rootStore.AppStore?.setMember({
                ...member,
                aisCustomizationId,
                pisCustomizationId,
            });
            this.rootStore.member.aisCustomizationId = aisCustomizationId;
            this.rootStore.member.pisCustomizationId = pisCustomizationId;

            yield this.rootStore.FeatureStore.handleFetchFeatures();
        }.bind(this),
    );

    @action
    handleSave = flow(
        function* (type, tabName = '') {
            this.loading = true;
            try {
                yield this.createCustomization(type, tabName);
                this.GenericSuccessStore.openModal();
            } catch (e) {
                console.error(e);
                this.rootStore.GenericErrorStore.openErrorModal(e);
            } finally {
                this.loading = false;
            }
        }.bind(this),
    );

    @action
    handlePreview = flow(
        function* (type, isConsent) {
            try {
                if (isConsent) {
                    this.previewText = sanitizeHTML(escapeEmptyTags(this.consent.text));
                    if (this.reversePaymentSupportText) {
                        this.previewText +=
                            this.addRpsClass(this.previewText) +
                            this.reversePaymentSupportText +
                            '</div>';
                    }
                    this.isConsentTabValuesChange(this.previewText);
                    this.previewText = this.formatTags(this.previewText);
                    this.previewText = escapeEmptyTags(this.previewText);
                } else {
                    yield this.createCustomization(type);
                    yield Router.push('/sandbox/auth-link');
                }
            } catch (e) {
                console.error(e);
                this.rootStore.GenericErrorStore.openErrorModal(e);
            }
        }.bind(this),
    );

    @action
    handleFetch = flow(
        function* (type) {
            try {
                this.initCustomizations();
                const member = this.rootStore.member;
                if (
                    member?.permissions?.includes(STANDING_ORDER) ||
                    member?.permissions?.includes(SINGLE_IMMEDIATE_PAYMENT) ||
                    member?.permissions?.includes(FUTURE_DATED) ||
                    member?.permissions?.includes(BULK_TRANSFER) ||
                    member?.permissions?.includes(PIS)
                ) {
                    this.isReversePayment = !!member.permissions.includes(REQUEST_REFUND_ACCOUNT);
                }
                yield this.getCustomization(member, type);
                const response = yield axios.get(`/api/member/${member.memberId}/profile`);
                this.displayName = response.data.profileName;

                if (!member?.permissions?.includes(PIS)) {
                    this.consentPlaceholder =
                        '{DISPLAY_NAME} custom consent text for {BUSINESS_NAME}, expires: {EXPIRATION_DATE}';
                    this.isAispOnly = true;
                }
                this.handlePreview(type, true);
            } catch (e) {
                console.error(e);
            }
        }.bind(this),
    );

    @action
    updateDisplayFeatures = () => {
        const { displayFeatures, setDisplayFeatures } = this.rootStore.FeatureStore;
        const dF = { ...displayFeatures };
        dF.colors = this.constructCSS();
        dF.fonts = this.constructFonts();
        dF.tppLogo = this.logo;
        setDisplayFeatures({ ...dF });
    };

    @action
    isConsentTabValuesChange = (consentValue) => {
        let currValue = this.regionalConfigurations[this.region]?.translations[
            `${this.language}.${this.consentType}.consent.text`
        ];
        if (this.consentType === 'pisp' && !this.isReversePayment) {
            currValue = this.getConsentTextWithoutReversePayment(currValue);
        }
        if (consentValue.includes('<a href')) {
            consentValue = consentValue.replace(/ target="_blank"/g, '');
            consentValue = consentValue.replace(/<a/g, '<a target="_blank"');
        }
        const condition = currValue !== consentValue;
        const { setIsConsentTabValueChange } = this.rootStore;
        setIsConsentTabValueChange(condition);
    };

    @action
    handleResetLogo = () => {
        this.logo = {};
        this.updateDisplayFeatures();
    };

    constructCSS = () => {
        const colors = {};
        Object.keys(this.colorOptions).forEach((key) => {
            if (this.colorOptions[key].value) {
                colors[key] = this.colorOptions[key].value;
            }
        });
        return colors;
    };

    constructFonts = () => {
        const fonts = {};
        Object.keys(this.fontSizeOptions).forEach((key) => {
            fonts[key] = this.fontSizeOptions[key].value + 'px';
        });
        Object.keys(this.fontWeightOptions).forEach((key) => {
            fonts[key] = this.fontWeightOptions[key].value;
        });
        fonts['font-primary'] = this.setFontFamily(this.fontPrimary);
        return fonts;
    };

    setFontFamily = () => {
        switch (this.fontPrimary) {
            case 'sans-serif':
                return 'sans-serif';
            case 'Source Sans Pro':
                return "'Source Sans Pro', sans-serif";
            default:
                return `'${this.fontPrimary}', 'Source Sans Pro', sans-serif`;
        }
    };

    formatTags = (tag) => {
        let text = tag;
        text = text
            .replace(/{EXPIRATION_DATE}/g, this.expirationDate)
            .replace(/{DISPLAY_NAME}/g, this.displayName)
            .replace(/{BUSINESS_NAME}/g, this.consent.name);
        return text;
    };

    flowType = (type) => {
        return type.slice(-1) === 'P'
            ? type.toLocaleLowerCase()
            : type.toLocaleLowerCase().concat('p');
    };

    addRpsClass = (text) => {
        const align = ['rt-right', 'rt-center', 'rt-left'].find((str) => text.includes(str));
        if (align) {
            return `<div class="rt-reverse-payments-support ${align}">`;
        }
        return '<div class="rt-reverse-payments-support">';
    };
    expirationDate = moment(new Date()).add(90, 'day').format('MMMM Do YYYY');
}

function setConsent(memberType, { text, name }) {
    // Resolves APE-584: Consent text and name cannot be defined when saving as a TYPE_ONE member. Member service will through error.
    return memberType === TYPE_ONE
        ? {
            text: '',
            name: '',
        }
        : {
            text,
            name,
        };
}

export default CustomizationStore;
