import { Button, FormControlLabel, Menu, Radio, RadioGroup, withStyles } from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import InlineSVG from 'react-inlinesvg';
import AppController from '../../../base/App.controller';
import { UNDER_RESELLER } from '../../../constants/clientType';
import questionMark from '../../../images/question-mark.svg';
import signOut from '../../../images/sign-out.svg';
import isType1Type2Dashboard from 'helpers/is-type1-type2-dashboard/isType1Type2Dashboard';
import { IMenuWrapper } from '../types';
import { IUserAccount, Itranslation } from 'types';
const { publicRuntimeConfig } = require('next/config').default();

const styles: any = () => ({
    root: {
        maxWidth: '300px',
        padding: '15px',
        '& .menuContainer': {
            width: '220px',
            '& .button': {
                display: 'flex',
                justifyContent: 'space-between',
                color: 'var(--milled-wine)',
                marginBottom: '10px',
                '&:last-child': {
                    marginBottom: '0px',
                },
                '& .icon': {
                    fill: 'var(--gainsboro)',
                },
                '&:hover, &:focus': {
                    '& .icon': {
                        fill: 'var(--primary)',
                    },
                },
                '&:active': {
                    '& .icon': {
                        fill: 'var(--active)',
                    },
                },
            },
        },
    },
    popover: {
        zIndex: '950 !important',
    },
});

const MenuWrapper: React.FC<IMenuWrapper> = ({ AppStore, classes, ...props }) => {
    const { t }: Itranslation = AppController.getTranslation(AppStore?.scope, 'common');
    const { userAccounts, user, scope } = AppStore;
    const [dashboardMenuExpanded, setDashboardMenuExpanded] = useState<boolean>(false);
    const [activeUser, setActiveUser] = useState<IUserAccount>();
    const menuRef = React.createRef<HTMLDivElement>();
    const [menuAnchor, setMenuAnchor] = useState<HTMLDivElement | null>(null);
    const multipleDashboardFlag = publicRuntimeConfig.userAccounts && isType1Type2Dashboard(scope);

    const handleRedirect = () => {
        window.open(publicRuntimeConfig.externalLinks.support.default, '_blank', 'noopener');
    };

    useEffect(() => {
        setActiveUser(
            userAccounts.find((userAccount: IUserAccount) => userAccount.id === user.id) || {},
        );
    }, [userAccounts]);

    const _handleSelectUserAccount = (id: string) => {
        AppStore.handleSelectUserAccount(userAccounts.find((acc: IUserAccount) => acc.id === id));
    };

    const AccountSwitch = () => {
        return (
            <Menu
                getContentAnchorEl={null}
                open={dashboardMenuExpanded}
                anchorEl={menuAnchor}
                onClose={() => setDashboardMenuExpanded(false)}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: -320,
                }}>
                <RadioGroup
                    aria-label="dashboard-selection-menu"
                    name="activeDashboard"
                    id="activeDashboardRadioGroup"
                    value={activeUser?.id}
                    onChange={(e) => {
                        _handleSelectUserAccount(e.target.value);
                        setDashboardMenuExpanded(false);
                    }}
                    style={{
                        width: 280,
                        padding: 10,
                    }}
                    className={'radioGroup'}>
                    {(userAccounts as Array<IUserAccount>).map((userAccount) => {
                        return (
                            <FormControlLabel
                                style={{ zIndex: 999 }}
                                key={userAccount.id}
                                value={userAccount.id}
                                control={<Radio />}
                                label={
                                    userAccount.tppName
                                        ? userAccount.tppName
                                        : `${userAccount.email} + ${userAccount.memberId}`
                                }
                            />
                        );
                    })}
                </RadioGroup>
            </Menu>
        );
    };

    return (
        <>
            <Menu
                getContentAnchorEl={null}
                keepMounted
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                PaperProps={{
                    className: classes.root,
                }}
                PopoverClasses={{
                    root: classes.popover,
                }}
                {...props}>
                <div className={classes.root}>
                    <div className="menuContainer">
                        {multipleDashboardFlag && userAccounts.length > 0 && (
                            <>
                                <div className="menuRefContainer" ref={menuRef}>
                                    <Button
                                        variant="text"
                                        color="secondary"
                                        className="button"
                                        id="userAccountsMenu"
                                        onClick={() => {
                                            setMenuAnchor(menuRef.current);
                                            setDashboardMenuExpanded(true);
                                        }}>
                                        <InlineSVG src={signOut} className="icon" />
                                        {t('switchDashboards')}
                                    </Button>
                                </div>
                                <AccountSwitch />
                            </>
                        )}
                        {AppStore?.client?.clientType !== UNDER_RESELLER && (
                            <Button
                                variant="contained"
                                color="secondary"
                                className="button"
                                onClick={handleRedirect}>
                                {t('needHelp')}
                                <InlineSVG src={questionMark} className="icon" />
                            </Button>
                        )}
                        <Button
                            variant="contained"
                            color="secondary"
                            className="button"
                            onClick={AppStore?.handleSignOut}>
                            {t('signOut')}
                            <InlineSVG src={signOut} className="icon" />
                        </Button>
                    </div>
                </div>
            </Menu>
        </>
    );
};

export default withStyles(styles, { name: 'MuiMenuWrapper' })(
    inject('AppStore')(observer(MenuWrapper)),
);
