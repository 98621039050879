import {
    Box,
    Collapse,
    Divider,
    Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    withStyles,
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import {
    KeyboardArrowDown,
    KeyboardArrowUp,
    NavigateBefore,
    NavigateNext,
} from '@material-ui/icons';
import { inject, observer } from 'mobx-react';
import React from 'react';
import Spinner from '../spinner/Spinner';
import LinkWrapper from './LinkWrapper';
//types
import {
    ICellConstructorComponentProps,
    ICellConstructorTextProps,
    IRow,
    ITableWrapper,
} from '../types';

// Style Icon
const StyledIcon = withStyles({
    root: {
        borderRadius: 4,
        backgroundColor: 'var(--white)',
    },
})(IconButton);

const styles = () => ({
    root: {
        '& .mainContainer': {
            marginTop: '40px',
            marginBottom: '30px',
            '&.emptyList': {
                color: 'var(--milled-wine)',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontSize: 'var(--fs-x-large)',
                minHeight: '344px',
                padding: '10px 10px',
            },
            '&.list': {
                '& .selectRow': {
                    cursor: 'pointer',
                },
                '& .subtable:nth-child(odd)': {
                    backgroundColor: 'var(--white)',
                },
                '& .subtable:nth-child(even)': {
                    backgroundColor: '#eee6f7',
                },
                '& .subtable:hover': {
                    backgroundColor: 'var(--gray-96)',
                },
                '& .subHeader': {
                    backgroundColor: '#fbfafc',
                },
                '& .draft': {
                    backgroundColor: 'var(--yellow)',
                },
            },
            '& .badge': {
                display: 'inline-block',
                lineHeight: '1em',
                padding: '7px 12px',
                backgroundColor: 'var(--badge-bg)',
                borderRadius: '14px',
                fontSize: 'var(--fs-small)',
                fontWeight: 'var(--font-weight-medium)',
                textTransform: 'capitalize',
                width: 'max-content',
                '&.error': {
                    backgroundColor: 'var(--bittersweet)',
                    color: 'white',
                },
                '&.processing': {
                    backgroundColor: 'var(--n26-wheat)',
                    color: 'white',
                },
                '&.initiated': {
                    backgroundColor: 'var(--active)',
                    color: 'white',
                },
                '&.sent': {
                    backgroundColor: 'var(--n26-wheat)',
                    color: 'white',
                },
                '&.invalid-status': {
                    backgroundColor: 'var(--bittersweet)',
                    color: 'white',
                },
                '&.success': {
                    backgroundColor: 'var(--dark-pastel-green)',
                    color: 'white',
                },
            },
            '& .actionIcon': {
                paddingRight: '40px',
                '& .MuiDivider-vertical': {
                    height: '34px',
                    display: 'inline-block',
                    verticalAlign: 'middle',
                },
                '& .arrowIcon': {
                    position: 'relative',
                    display: 'inline-block',
                    verticalAlign: 'middle',
                    paddingRight: '2px',
                    width: '22px',
                    height: '34px',
                },
            },
        },
        '& .pageNumber': {
            color: 'var(--spun-pearl)',
        },
        '.paginationContainer': {
            marginTop: '15px',
        },
    },
});

const TableWrapper: React.FC<ITableWrapper> = ({
    TableWrapperStore,
    header,
    cellTemplates,
    enablePagination,
    disableRowSelect,
    loading,
    noDataMessage,
    callback,
    serverSidePagination = false,
    pageCallback,
    classes,
    endIcon,
    hidePageNumber = false,
    canExpand = false,
    subHeader = [],
    subCellTemplates,
}) => {
    const {
        data,
        pageNumber,
        currentPage,
        maxRowsPerPage,
        selected,
        handleClickPage,
        updateSelected,
        toggleSubsection,
        toggleNestedChildren,
    } = TableWrapperStore;

    const clearSelected = () => {
        if (!disableRowSelect && callback) {
            updateSelected({});
            callback({});
        }
    };

    const handleSelectRow = async (row: IRow) => {
        if (!disableRowSelect && callback) {
            updateSelected(row);
            await callback(row);
        }
    };

    const handlePageChange = async (
        event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
        navigation: string,
    ) => {
        clearSelected();
        handleClickPage(event, navigation);
        if (serverSidePagination && pageCallback) {
            await pageCallback(TableWrapperStore.currentPage);
            return;
        }
        if (pageCallback) {
            pageCallback(TableWrapperStore.currentPage);
        }
    };

    const _handleParentClick = async (row: IRow, index: number) => {
        if (canExpand && row.section) {
            return toggleSubsection(index);
        }
        return await handleSelectRow(row);
    };

    const renderChildren = (row: IRow) => {
        if (canExpand && row.isSectionOpen && row.childrenList?.length) {
            return handleChildren(row.childrenList);
        }
    };

    const _handleNestedChildrenClick = async (row: IRow) => {
        return await handleSelectRow(row);
    };

    const _handleRowExpand = (row: IRow, index: number) => {
        if (canExpand && row.childrenList) {
            toggleNestedChildren(row);
        }
        if (canExpand && row.section) {
            return toggleSubsection(index);
        }
    };

    const handleChildren = (data: IRow[]) => {
        return data.map((row, index) => {
            return (
                <React.Fragment key={index}>
                    <TableRow
                        hover
                        key={index}
                        className={`row ${row.isDraft && 'draft'}`}
                        onClick={() => _handleNestedChildrenClick(row)}
                        selected={selected === row}>
                        {canExpand && (row.childrenList?.length || 0) > 0 && (
                            <TableCell>
                                <IconButton
                                    aria-label="expand row"
                                    size="small"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        _handleRowExpand(row, index);
                                    }}>
                                    {row.isSectionOpen ? (
                                        <KeyboardArrowUp />
                                    ) : (
                                        <KeyboardArrowDown />
                                    )}
                                </IconButton>
                            </TableCell>
                        )}
                        {canExpand && row.childrenList?.length === 0 && <TableCell />}
                        {cellTemplates.map((template) => template(row))}
                    </TableRow>
                    {renderChildren(row)}
                </React.Fragment>
            );
        });
    };

    const handlePagination = () => {
        let currentPageItems: IRow[];

        if (enablePagination) {
            const indexOfLastItem = currentPage * maxRowsPerPage;
            const indexOfFirstItem = indexOfLastItem - maxRowsPerPage;
            currentPageItems = data.slice(indexOfFirstItem, indexOfLastItem);
        } else {
            currentPageItems = data;
        }
        return currentPageItems.map((row, index) => {
            return (
                <React.Fragment key={index}>
                    <TableRow
                        hover
                        key={index}
                        className={`${disableRowSelect ? 'row' : 'selectRow'} ${
                            row.isDraft && 'draft'
                        }`}
                        onClick={() => _handleParentClick(row, index)}
                        selected={selected === row && !row.section}>
                        {canExpand && (row.section || (row.childrenList?.length || 0) > 0) && (
                            <TableCell>
                                <IconButton
                                    aria-label="expand row"
                                    size="small"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        _handleRowExpand(row, index);
                                    }}>
                                    {row.isSectionOpen ? (
                                        <KeyboardArrowUp />
                                    ) : (
                                        <KeyboardArrowDown />
                                    )}
                                </IconButton>
                            </TableCell>
                        )}
                        {canExpand &&
                            !row.section &&
                            (row.childrenList === undefined || row.childrenList.length === 0) && (
                                <TableCell />
                            )}
                        {cellTemplates.map((template) => template(row))}
                        {endIcon && (
                            <TableCell className={'actionIcon'} align={'right'}>
                                <Divider orientation="vertical" />
                                <span className={'arrowIcon'}>{endIcon}</span>
                            </TableCell>
                        )}
                    </TableRow>
                    {canExpand && row.isSectionOpen && row.section && (
                        <TableRow className={'section'}>
                            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
                                <Collapse in timeout="auto" unmountOnExit>
                                    <Box>
                                        <Table aria-label="sub section">
                                            {subHeader.length > 0 && (
                                                <TableHead>
                                                    <TableRow className={'subHeader'} key={'head'}>
                                                        {subHeader.map((key) => (
                                                            <TableCell key={key} align={'center'}>
                                                                {key}
                                                            </TableCell>
                                                        ))}
                                                    </TableRow>
                                                </TableHead>
                                            )}
                                            <TableBody>
                                                {row.section.map((sRow, sIndex) => (
                                                    <TableRow
                                                        hover
                                                        key={sIndex}
                                                        className={
                                                            disableRowSelect
                                                                ? 'row subtable'
                                                                : 'selectRow subtable'
                                                        }
                                                        onClick={() => handleSelectRow(sRow)}
                                                        selected={selected === sRow}>
                                                        {subCellTemplates!.map((template) =>
                                                            template(sRow),
                                                        )}
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </Box>
                                </Collapse>
                            </TableCell>
                        </TableRow>
                    )}
                    {renderChildren(row)}
                </React.Fragment>
            );
        });
    };

    return (
        <div className={classes.root}>
            {loading ? (
                <Paper className={'mainContainer emptyList'}>
                    <Spinner type={Spinner.TYPE_LARGE} color={Spinner.COLOR_SECONDARY} />
                </Paper>
            ) : !data?.length ? (
                <Paper className={'mainContainer emptyList'}>
                    <div>{noDataMessage}</div>
                </Paper>
            ) : (
                <Paper className={'mainContainer list'}>
                    <Table>
                        <TableHead>
                            <TableRow key={'head'}>
                                {canExpand && <TableCell />}
                                {header.map((key) => (
                                    <TableCell key={key || Math.random()} align={'center'}>
                                        {key}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>{handlePagination()}</TableBody>
                    </Table>
                </Paper>
            )}
            {pageNumber > 1 && (
                <Grid
                    container
                    direction="row"
                    alignItems="center"
                    justify="center"
                    spacing={1}
                    className={'paginationContainer'}>
                    <Grid item xs={1}>
                        {currentPage !== 1 && (
                            <StyledIcon
                                className={'prevPage'}
                                onClick={(e) => handlePageChange(e, 'before')}>
                                <NavigateBefore htmlColor={'#DCDCDC'} />
                            </StyledIcon>
                        )}
                    </Grid>
                    <Grid item xs={1}>
                        <div className={'pageNumber'}>
                            {hidePageNumber ? currentPage : currentPage + ' of ' + pageNumber}
                        </div>
                    </Grid>
                    <Grid item xs={1}>
                        {currentPage !== pageNumber && (
                            <StyledIcon
                                className={'nextPage'}
                                onClick={(e) => handlePageChange(e, 'next')}>
                                <NavigateNext htmlColor={'#DCDCDC'} />
                            </StyledIcon>
                        )}
                    </Grid>
                </Grid>
            )}
        </div>
    );
};

TableWrapper.propTypes = {
    enablePagination: (props): any => {
        if (props.enablePagination && props.serverSidePagination) {
            console.error(
                "Warning: Failed prop type: Only one of props 'enablePagination' or 'serverSidePagination' can be true in 'TableWrapper'.",
            );
        }
    },
    pageCallback: function (props, propName): any {
        if (
            props['serverSidePagination'] &&
            (props[propName] === undefined || typeof props[propName] !== 'function')
        ) {
            console.error('Warning: Failed prop type: Please provide a pageCallback function!');
        }
    },
};

export const cellConstructor = {
    text: function text({
        row,
        keyId,
        field,
        value = '',
        className = '',
        isBadge = false,
        link = '',
    }: ICellConstructorTextProps) {
        const defaultClass = isBadge ? 'badge' : 'default';
        let cellValue = value ? value : row[field] ? row[field] : '-';
        if (isBadge) {
            cellValue = cellValue.toLowerCase();
        }
        return (
            <TableCell
                key={`${row[keyId]}${field}`}
                align={'center'}
                className={!isBadge ? className : ''}>
                {link ? (
                    <LinkWrapper href={link} className={'link'} target="_blank" rel="noopener">
                        {cellValue}
                    </LinkWrapper>
                ) : (
                    <span className={className ? className : defaultClass}>{cellValue}</span>
                )}
            </TableCell>
        );
    },
    render: ({ row, keyId, field, component, TableCellProps }: ICellConstructorComponentProps) => {
        return (
            <TableCell key={`${row[keyId]}${field}`} align="center" {...TableCellProps}>
                {component(row[field])}
            </TableCell>
        );
    },
};

export default withStyles(styles, { name: 'TableWrapper' })(
    inject('TableWrapperStore')(observer(TableWrapper)),
);
