import axios from 'axios';
import { action, computed, configure, flow, observable } from 'mobx';
import { useStaticRendering } from 'mobx-react';
import moment from 'moment';

import ModalStore from 'components/common/modals/ModalStore';

// constant
import { isoAlpha3 } from 'constants/countries';
import { ON, OFF } from 'constants/inputField';
import { EUR, GBP } from 'constants/paymentCurrency';
import { SINGLE_IMMEDIATE_PAYMENT } from 'constants/restrictions';
// types
import type PaymentInitiationRestrictionsStore from 'components/configuration/payment-initiation-restrictions/PaymentInitiationRestrictionsStore';
import {
    IFieldValuesPaymentInitRest,
    IRestrictionRow,
    IRestrictionRowChild,
    Operation,
} from '../types';
import React from 'react';

const isServer = typeof window === 'undefined';
useStaticRendering(isServer);
configure({
    enforceActions: 'observed',
});

class PaymentInitiationRestrictionsModalStore extends ModalStore {
    constructor(public rootStore: PaymentInitiationRestrictionsStore) {
        super();
        this.rootStore = rootStore;
    }

    operands = ['==', '<=', '>=', '!=', 'matches', 'in'];

    operator = ['OR', 'AND', 'NOT'];

    mappedFields: { [k: string]: string } = {
        'payment amount (double)': 'paymentAmount',
        'payment currency (string)': 'paymentCurrency',
        'payer bank (string)': 'payerBank',
        'payer country (string)': 'payerCountry',
        'beneficiary iban (string)': 'beneficiaryIban',
        'daily transaction count (long)': 'dailyTransactionCount',
        'weekly transaction count (long)': 'weeklyTransactionCount',
        'monthly transaction count (long)': 'monthlyTransactionCount',
        'daily total amount (double)': 'dailyTotalAmount',
        'weekly total amount (double)': 'weeklyTotalAmount',
        'monthly total amount (double)': 'monthlyTotalAmount',
        'total amount currency (string)': 'totalAmountCurrency',
    };

    mappedValues: { [k: string]: string } = {
        paymentAmount: 'Payment Amount (Double)',
        paymentCurrency: 'Payment Currency (String)',
        payerBank: 'Payer Bank (String)',
        payerCountry: 'Payer Country (String)',
        beneficiaryIban: 'Beneficiary Iban (String)',
        dailyTransactionCount: 'Daily Transaction Count (Long)',
        weeklyTransactionCount: 'Weekly Transaction Count (Long)',
        monthlyTransactionCount: 'Monthly Transaction Count (Long)',
        dailyTotalAmount: 'Daily Total Amount (Double)',
        weeklyTotalAmount: 'Weekly Total Amount (Double)',
        monthlyTotalAmount: 'Monthly Total Amount (Double)',
        totalAmountCurrency: 'Total Amount Currency (String)',
    };

    fields = [
        'Payment Amount (Double)',
        'Payment Currency (String)',
        'Payer Bank (String)',
        'Payer Country (String)',
        'Beneficiary Iban (String)',
        'Daily Transaction Count (Long)',
        'Weekly Transaction Count (Long)',
        'Monthly Transaction Count (Long)',
        'Daily Total Amount (Double)',
        'Weekly Total Amount (Double)',
        'Monthly Total Amount (Double)',
        'Total Amount Currency (String)',
    ];

    acceptedFields = [
        'paymentAmount',
        'paymentCurrency',
        'payerBank',
        'payerCountry',
        'beneficiaryIban',
        'dailyTransactionCount',
        'weeklyTransactionCount',
        'monthlyTransactionCount',
        'dailyTotalAmount',
        'weeklyTotalAmount',
        'monthlyTotalAmount',
        'totalAmountCurrency',
    ];

    mappedOperators: { [k: string]: string } = {
        ' or ': ' || ',
        ' and ': ' && ',
    };

    mappedOperatorValues: { [k: string]: string } = {
        '||': 'OR',
        '&&': 'AND',
    };

    fieldValues: IFieldValuesPaymentInitRest = {
        payerCountry: Object.values(isoAlpha3),
        paymentCurrency: [EUR, GBP],
    };

    allowedOperandsPerField: { [k: string]: string[] } = {
        paymentAmount: ['==', '<=', '>=', '!='],
        paymentCurrency: ['==', '!=', 'matches', 'in'],
        payerBank: ['==', '!=', 'matches', 'in'],
        payerCountry: ['==', '!=', 'matches', 'in'],
        beneficiaryIban: ['==', '!=', 'matches', 'in'],
        dailyTransactionCount: ['==', '<=', '>=', '!='],
        weeklyTransactionCount: ['==', '<=', '>=', '!='],
        monthlyTransactionCount: ['==', '<=', '>=', '!='],
        dailyTotalAmount: ['==', '<=', '>=', '!='],
        weeklyTotalAmount: ['==', '<=', '>=', '!='],
        monthlyTotalAmount: ['==', '<=', '>=', '!='],
        totalAmountCurrency: ['==', '!=', 'matches', 'in'],
    };

    @observable
    queryOptionsList = [...this.fields];
    @observable
    currentOptions = [...this.fields];
    @observable
    query = '';
    @observable
    showSuggestions = false;
    @observable
    currentText = '';
    @observable
    queryText = '';
    @observable
    activeSuggestion = 0;
    @observable
    productType = SINGLE_IMMEDIATE_PAYMENT;
    @observable
    name = '';
    @observable
    type: Operation = Operation.ADD;
    @observable
    errorText = 'Parenthesis missing';
    @observable
    previousQuery = '';
    @observable
    selectedRestrictionRow: Partial<IRestrictionRow> = {};
    @observable
    payloadQuery = '';
    @observable
    loading = false;
    @observable
    previousRuleName = '';
    @observable
    isFieldValue = false;
    @observable
    field = '';

    @action
    setType = (type: Operation) => {
        this.type = type;
    };

    @action
    setQuery = (value: string) => {
        this.query = value;
        if (this.query === '') {
            this.queryText = '';
            this.setQueryOptionFilter(this.fields);
            this.setCurrentOptionsList(this.fields);
            this.setShowSuggestions(true);
            this.setActiveSuggestion(0);
        }
        this.setCurrentText(value);
    };

    @computed
    get isPreviousRule() {
        return this.previousQuery === this.query && this.previousRuleName === this.name;
    }

    @computed
    get isDisable() {
        const hasQuery = this.query ? ON : OFF,
            hasName = this.name ? ON : OFF;
        return !(
            hasName === ON &&
            hasQuery === ON &&
            this.isValidParenthesis &&
            !this.isPreviousRule
        );
    }

    @action
    appendQueryRule = (value: string) => {
        if (this.fields.includes(value)) {
            this.field = value;
        }
        if (this.isFieldValue && this.queryText[this.queryText.length - 1] !== '"') {
            value = '"' + value + '"';
        }
        this.query = this.queryText + value;
        this.isFieldValue = false;
    };

    @action
    onClick = () => {
        if (this.query === '') {
            this.setShowSuggestions(true);
        }
    };

    @action
    setShowSuggestions = (value: boolean) => {
        this.showSuggestions = value;
    };

    @action
    setActiveSuggestion = (index: number) => {
        this.activeSuggestion = index;
    };

    @action
    setPreviousRule = (queryRule: string, ruleName: string) => {
        this.formatPayloadQuery(queryRule);
        this.previousQuery = this.query;
        this.previousRuleName = this.name = ruleName;
    };

    @action
    handleFilterOptions = () => {
        let temp = this.query;
        temp = temp.replace(this.queryText, '');
        if (this.showSuggestions) {
            const list = this.currentOptions.filter((option) => {
                const value = temp.toLowerCase();
                return option.toLowerCase().includes(value);
            });
            this.setQueryOptionFilter(list);
        }
    };

    @action
    setQueryOptionFilter = (list: string[]) => {
        this.queryOptionsList = [...list];
    };

    @action
    setSelectedRestrictionRow = (row: IRestrictionRow) => {
        this.selectedRestrictionRow = row;
    };

    @action
    setCurrentText = (value: string) => {
        this.currentText = value;
    };

    @action
    setCurrentOptionsList = (list: string[]) => {
        this.currentOptions = [...list];
    };

    /* istanbul ignore next */
    @action
    checkValueSuggestionType = () => {
        const length = this.query.length;
        let text = this.query;
        text = text.replace(this.queryText, '');
        text = text.substring(0, text.length - 1);
        if (
            this.query[length - 1] === ' ' &&
            this.query[length - 2] === ')' &&
            Object.prototype.hasOwnProperty.call(
                this.allowedOperandsPerField,
                this.mappedFields[this.field.toLowerCase()],
            )
        ) {
            const allowedOperands = this.allowedOperandsPerField[
                this.mappedFields[this.field.toLowerCase()]
            ];
            this.setQueryOptionFilter(allowedOperands);
            this.setCurrentOptionsList(allowedOperands);
            this.setShowSuggestions(true);
            this.queryText = this.query;
            this.isFieldValue = false;
        } else if (
            this.query[length - 1] === ' ' &&
            (this.query[length - 2] === '"' || this.isDigit(this.query[length - 2]))
        ) {
            this.setQueryOptionFilter(this.operator);
            this.setCurrentOptionsList(this.operator);
            this.setShowSuggestions(true);
            this.queryText = this.query;
            this.isFieldValue = false;
        } else if (this.query[length - 1] === ' ' && this.operator.includes(text)) {
            this.setQueryOptionFilter(this.fields);
            this.setCurrentOptionsList(this.fields);
            this.setShowSuggestions(true);
            this.queryText = this.query;
            this.isFieldValue = false;
        } else if (
            this.mappedFields[this.field.toLowerCase()] in this.fieldValues &&
            ((this.query[length - 1] === ' ' && this.operands.includes(text)) ||
                (this.query[length - 1] === '"' && this.query[length - 2] === ' '))
        ) {
            const currentField = this.mappedFields[this.field.toLowerCase()];
            this.setQueryOptionFilter(this.fieldValues[currentField]);
            this.setCurrentOptionsList(this.fieldValues[currentField]);
            this.isFieldValue = true;
            this.setShowSuggestions(true);
            this.queryText = this.query;
        }
    };

    @computed
    get isValidParenthesis() {
        const stack = [];
        const map = new Map([
            ['(', ')'],
            ['[', ']'],
            ['{', '}'],
        ]);
        for (let i = 0; i < this.query.length; i++) {
            if (map.has(this.query[i])) {
                stack.push(map.get(this.query[i]));
            } else if ([')', '}', ']'].includes(this.query[i])) {
                stack.pop();
            }
        }
        return stack.length === 0;
    }

    @action
    handleChange = (event: React.ChangeEvent<any>) => {
        this.name = event.target.value;
    };

    @action
    handleSubmit = flow(
        function* (this: PaymentInitiationRestrictionsModalStore) {
            this.loading = true;
            try {
                const memberId = this.rootStore.targetMemberId;
                this.formatQuery(this.query);
                if (this.type === 'addRule') {
                    const body = {
                        memberId: memberId,
                        restrictionId: this.rootStore.restrictionId,
                        rule: {
                            name: this.name,
                            text: `SipRestrictedFields(${this.payloadQuery})`,
                        },
                    };
                    yield axios.post('/api/member/restriction-rule', body);
                } else {
                    const body = {
                        targetMemberId: memberId,
                        restrictionPayload: {
                            productType: this.productType,
                            rulePayloads: [
                                {
                                    name: this.name,
                                    text: `SipRestrictedFields(${this.payloadQuery})`,
                                },
                            ],
                        },
                    };
                    yield axios.post('/api/member/payment-initiation-restrictions', body);
                }
                this.closeModal();
                this.handleClose();
                yield this.rootStore.handleFetch();
            } catch (e) {
                console.error(e);
                this.rootStore.GenericErrorStore.openErrorModal(e);
            } finally {
                this.loading = false;
            }
        }.bind(this),
    );

    @action
    handleModify = flow(
        function* (this: PaymentInitiationRestrictionsModalStore) {
            this.loading = true;
            try {
                this.formatQuery(this.query);
                if (this.type === 'modifyRule') {
                    const body = {
                        memberId: this.rootStore.targetMemberId,
                        restrictionId: this.rootStore.restrictionId,
                        ruleId: this.rootStore.ruleId,
                        updatedRule: {
                            id: this.selectedRestrictionRow.id,
                            name: this.name,
                            text: `SipRestrictedFields(${this.payloadQuery})`,
                            enabled: this.selectedRestrictionRow.enabled,
                            createdAt: this.selectedRestrictionRow.createdAt,
                        },
                    };
                    yield axios.put('/api/member/restriction-rule', body);
                } else {
                    if (!this.selectedRestrictionRow.childrenList) return;
                    this.selectedRestrictionRow.childrenList[0] = {
                        ...this.selectedRestrictionRow.childrenList[0],
                        name: this.name,
                        text: `SipRestrictedFields(${this.payloadQuery})`,
                    };
                    const rules: IRestrictionRowChild[] = [];
                    this.selectedRestrictionRow.childrenList.map((value) => {
                        const rule = {
                            id: value.id,
                            name: value.name,
                            text: value.text,
                            enabled: value.enabled,
                            createdAt: value.createdAt,
                        };
                        rules.push(rule);
                    });
                    const body = {
                        restrictionId: this.selectedRestrictionRow.id,
                        updatedRestriction: {
                            id: this.selectedRestrictionRow.id,
                            targetMemberId: this.selectedRestrictionRow.targetMemberId,
                            ownerMemberId: this.selectedRestrictionRow.ownerMemberId,
                            productType: this.selectedRestrictionRow.productType,
                            createdAt: this.selectedRestrictionRow.createdAt,
                            updatedAt: moment(Date.now()).toDate().getTime(),
                            enabled: this.selectedRestrictionRow.enabled,
                            rules: rules,
                        },
                    };
                    yield axios.put('/api/member/payment-initiation-restrictions', body);
                }
                this.closeModal();
                this.handleClose();
                yield this.rootStore.handleFetch();
            } catch (e) {
                console.error(e);
                this.rootStore.GenericErrorStore.openErrorModal(e);
            } finally {
                this.loading = false;
            }
        }.bind(this),
    );

    @action
    handleClose = () => {
        this.query = '';
        this.queryText = '';
        this.payloadQuery = '';
        this.queryOptionsList = [...this.fields];
        this.name = '';
        this.isFieldValue = false;
        this.field = '';
        this.setShowSuggestions(false);
        this.setCurrentOptionsList(this.fields);
    };

    @action
    formatQuery = (query: string) => {
        this.payloadQuery = query;
        const queryFields = [...this.fields];
        let pQuery = query;
        queryFields.map((field) => {
            const formatField = field.replace(/([()])/g, '\\$1');
            const regExpField = new RegExp(formatField, 'gi');
            pQuery = pQuery.replace(regExpField, this.mappedFields[field.toLowerCase()]);
        });
        [' OR ', ' AND '].map((field) => {
            const value = new RegExp(field, 'gi');
            pQuery = pQuery.replaceAll(value, this.mappedOperators[field.toLowerCase()]);
        });
        this.payloadQuery = pQuery;
    };

    @action
    formatPayloadQuery = (query: string) => {
        this.query = query;
        const queryValues = [...this.acceptedFields];
        let pQuery = query;
        queryValues.map((value) => {
            pQuery = pQuery.replaceAll(new RegExp(value, 'gi'), this.mappedValues[value]);
        });
        ['||', '&&'].map((field) => {
            const value = field;
            pQuery = pQuery.replaceAll(value, this.mappedOperatorValues[value]);
        });
        this.query = pQuery;
    };

    isDigit = (number: string) => {
        return /^\d$/.test(number);
    };
}

export default PaymentInitiationRestrictionsModalStore;
