import { Divider, Grid, MenuItem, Tooltip } from '@material-ui/core';
import SettingsBackupRestoreSharpIcon from '@material-ui/icons/SettingsBackupRestoreSharp';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';

import AppController from 'base/App.controller';
import successIcon from 'images/password-reset.svg';

import GenericSuccessModal from 'components/common/modals/success/GenericSuccess';
import StyledIcon from 'components/common/styled-icon/transparent-bg-on-hover/StyledIcon';
import ButtonWrapper from 'components/common/wrappers/ButtonWrapper';
import SelectWrapper from 'components/common/wrappers/SelectWrapper';
import TextFieldWrapper from 'components/common/wrappers/TextFieldWrapper';
import ColorPicker from './color-picker/ColorPicker';

const { publicRuntimeConfig } = require('next/config').default();
//constants
const { ADMIN } = require('constants/roles');

function CSS({ AppStore, CustomizationStore }) {
    const fileInputRef = useRef(null);
    const { t } = AppController.getTranslation(AppStore.scope, 'configuration-css');
    const {
        loading,
        getCustomization,
        handleColorChange,
        isCustomizationFetched,
        isUploaded,
        isFilled,
        isFormChanged,
        colorOptions,
        logo,
        handleUpload,
        handleSave,
        handlePreview,
        rootStore,
        handleResetLogo,
        fontSizeOptions,
        fontWeightOptions,
        handleCSSChange,
        defaultFontWeightValues,
        defaultFontFamilyValues,
        handleFontFamilyChange,
        fontPrimary,
    } = CustomizationStore;

    const resetLogo = () => {
        fileInputRef.current.value = null;
        handleResetLogo();
    };

    useEffect(() => {
        getCustomization(rootStore.member);
    }, []);

    return (
        <div className={'CSS'}>
            <h2>{t('uploadLogo')}</h2>
            <Grid container spacing={2}>
                {isCustomizationFetched && isUploaded && (
                    <Grid item xs={12}>
                        <img src={logo.data} className={'logo'} />
                    </Grid>
                )}
                <Grid item xs={6} className={'buttonContainer'}>
                    <TextFieldWrapper
                        name="inputFile"
                        type="file"
                        inputRef={fileInputRef}
                        onChange={handleUpload}
                        hideOptional
                    />
                </Grid>
                <Grid>
                    <Tooltip title={t('resetLogo')}>
                        <StyledIcon
                            className={'resetLogo'}
                            onClick={() => resetLogo()}
                            aria-label={'Resets Logo'}>
                            <SettingsBackupRestoreSharpIcon />
                        </StyledIcon>
                    </Tooltip>
                </Grid>
            </Grid>
            <div>
                <h2>{t('fontFamily')}</h2>
            </div>
            <Grid container spacing={2} className={'colors'}>
                <Grid container item xs={6}>
                    <Grid item xs={4} className={'colorName'}>
                        <span>{t('font-primary')}</span>
                    </Grid>
                    <Grid item xs={4} className={'selector fontPrimary'}>
                        <SelectWrapper
                            name="font-primary"
                            value={fontPrimary}
                            onChange={handleFontFamilyChange}
                            hideOptional>
                            {defaultFontFamilyValues.map((s) => (
                                <MenuItem key={s} value={s}>
                                    <Tooltip title={t('tooltip.font-primary')}>
                                        <div>{s}</div>
                                    </Tooltip>
                                </MenuItem>
                            ))}
                        </SelectWrapper>
                    </Grid>
                </Grid>
            </Grid>
            <div>
                <h2>{t('styles')}</h2>
            </div>
            <Grid container spacing={2} className={'colors'}>
                {Object.keys(colorOptions).map((key) => (
                    <Grid container item xs={6} key={key}>
                        <Grid item xs={4} className={'colorName'}>
                            <span>{t(`colorOptions.${key}`)}</span>
                        </Grid>
                        <Grid item xs={4} className={'colorSelector'}>
                            <ColorPicker
                                name={key}
                                value={colorOptions[key].value}
                                placeholder={t(`placeholder.${key}`)}
                                onChange={handleColorChange}
                                Store={colorOptions[key].colorPickerStore}
                                tooltipText={t(`tooltip.${key}`)}
                            />
                        </Grid>
                    </Grid>
                ))}
            </Grid>
            <div>
                <h2>{t('fontWeights')}</h2>
            </div>
            <Grid container spacing={2} className={'colors'}>
                {Object.keys(fontWeightOptions).map((key) => (
                    <Grid container item xs={6} key={key}>
                        <Grid item xs={4} className={'colorName'}>
                            <span>{t(`fontWeightOptions.${key}`)}</span>
                        </Grid>
                        <Grid item xs={4} className={'selector fontWeights'}>
                            <SelectWrapper
                                name={key}
                                value={fontWeightOptions[key].value}
                                onChange={(e) => handleCSSChange(e, 'fontWeightOptions')}
                                hideOptional>
                                {defaultFontWeightValues.map((s) => (
                                    <MenuItem key={s} value={s}>
                                        <Tooltip title={t(`tooltip.${key}`)}>
                                            <div>{s}</div>
                                        </Tooltip>
                                    </MenuItem>
                                ))}
                            </SelectWrapper>
                        </Grid>
                    </Grid>
                ))}
            </Grid>
            <div>
                <h2>{t('fontSize')}</h2>
            </div>
            <Grid container spacing={2} className={'colors'}>
                {Object.keys(fontSizeOptions).map((key) => (
                    <Grid container item xs={6} key={key}>
                        <Grid item xs={4} className={'colorName'}>
                            <span>{t(`fontSizeOptions.${key}`)}</span>
                        </Grid>
                        <Grid item xs={4} className={'selector fontSize'}>
                            <TextFieldWrapper
                                name={key}
                                type="number"
                                value={fontSizeOptions[key].value}
                                onChange={(e) => handleCSSChange(e, 'fontSizeOptions')}
                                tooltipText={t(`tooltip.${key}`)}
                                hideOptional
                            />
                        </Grid>
                    </Grid>
                ))}
            </Grid>
            <Grid className={'divider'} item xs={12}>
                <Divider />
            </Grid>
            <Grid className={'buttonContainer'} container spacing={4}>
                <Grid item xs={6} />
                {AppStore.scope !== ADMIN && !publicRuntimeConfig.features.prod && (
                    <Grid item xs={3}>
                        <ButtonWrapper
                            onClick={() => handlePreview('', false)}
                            className="previewButton"
                            disabled={!isFilled}>
                            {t('preview')}
                        </ButtonWrapper>
                    </Grid>
                )}
                <Grid item xs={3}>
                    <ButtonWrapper
                        loading={loading}
                        onClick={() => handleSave()}
                        className="submitButton"
                        disabled={!isFormChanged}>
                        {t('common:save')}
                    </ButtonWrapper>
                </Grid>
            </Grid>
            <GenericSuccessModal
                Store={CustomizationStore.GenericSuccessStore}
                title={t('modalTitle')}
                subtitle={t('modalDescription')}
                buttonText={t('common:okay')}
                icon={successIcon}
            />
        </div>
    );
}

CSS.propTypes = {
    AppStore: PropTypes.object.isRequired,
    CustomizationStore: PropTypes.object.isRequired,
};

export default inject('AppStore')(observer(CSS));
